import Phaser from 'phaser';
import ImageButton from '../components/Button';
import SimpleImageButton from '../components/SimpleImageButton';
import EquipSlot from '../components/EquipSlot';
import SkillButton from '../components/SkillButton';
import FloatingNumbersPlugin from '../components/FloatingNumbersPlugin';
import ProgressBar from '../components/ProgressBar';

export default class Example extends Phaser.Scene {
	constructor() {
		super('MainScene');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'MainScene' });
	}

	preload() {
		this.load.scenePlugin('floatingNumbersPlugin', FloatingNumbersPlugin, 'floatingNumbersPlugin', 'floatingNumbers');
	}

	createNumber(targetObj, divide = 1) {
		if (this.currentTurn >= this.battleInfo.length) {
			return;
		}

		//console.log('current turn' + this.currentTurn);
		//console.log('battleInfo' + this.battleInfo.length);

		var dognumber = this.floatingNumbers.createFloatingText({
			textOptions: {
				fontFamily: 'Barlow Condensed',
				fontSize: 42,
				color: '#fff',
				strokeThickness: 2,
				fontWeight: 'bold',
				stroke: '#000000',
				shadow: {
					offsetX: 0,
					offsetY: 0,
					color: '#000',
					blur: 4,
					stroke: true,
					fill: false,
				},
			},
			text: Math.floor(this.battleInfo[this.currentTurn].damage / divide),
			align: 'top-center',
			parentObject: targetObj,
			animation: 'up',
			timeToLive: 500,
			animationEase: 'Sine.easeOut',
		});
	}

	timerEvent() {
		if (this.battleInfo == null || this.battleInfo.length == 0 || this.currentTurn >= this.battleInfo.length - 1) {
			this.resetBattleField();
			this.loadNewOpponent();
			return;
		}
		this.currentTurn += 1;

		var attackChar = null;
		var hitChar = null;
		var attackSkill = null;
		var hpTrack = null;
		var skillBtn = null;

		if (this.currentTurn % 2 == 0) {
			attackChar = this.rightPlayer;
			attackSkill = this.rightSkill;
			hitChar = this.leftPlayer;
			hpTrack = this.leftHP;
			skillBtn = this.leftSkill;
		} else {
			attackChar = this.leftPlayer;
			attackSkill = this.leftSkill;
			hitChar = this.rightPlayer;
			hpTrack = this.rightHP;
			skillBtn = this.rightSkill;
		}

		attackChar.animationState.addListener({
			complete: entry => {
				attackChar.animationState.clearListeners();
				attackChar.animationState.setAnimation(0, 'Idle', true);
			},
			event: (entry, event) => {
				if (event.data.name == 'Hit') {
					if (attackSkill.skillName == 'skill_1' && this.battleInfo[this.currentTurn].isSkill) {
						var impact = this.add.spine(hitChar.x, hitChar.y, 'dogkong-impact-data', 'dogkong-impact-atlas');
						impact.animationState.addListener({
							complete: entry => {
								this.scene.remove(impact);
								impact.destroy();
							},
						});

						impact.setOrigin(0.5, 0.5);
						impact.scaleX = 0.5;
						impact.scaleY = 0.5;
						impact.animationState.setAnimation(0, 'Skill', false);
					}
					if (attackSkill.skillName == 'skill_2' && this.battleInfo[this.currentTurn].isSkill) {
						this.createNumber(hitChar, 5);

						//console.log('Dogzila hit ' + JSON.stringify(event));
					} else {
						this.createNumber(hitChar);
					}

					if (hitChar == this.rightPlayer) {
						if (attackSkill.skillName == 'skill_2' && this.battleInfo[this.currentTurn].isSkill) {
							this.createReward(1);
						} else {
							this.createReward();
						}
					}

					hpTrack.setProgress(this.battleInfo[this.currentTurn].newHP);
					skillBtn.setProgress(this.battleInfo[this.currentTurn].skillGauge);

					if (this.battleInfo[this.currentTurn].newHP < 0) {
						hitChar.animationState.setAnimation(0, 'Die', false);
					} else {
						hitChar.animationState.setAnimation(0, 'Hit', false);
						hitChar.animationState.addAnimation(0, 'Idle', true);
					}
				}
			},
		});

		if (this.battleInfo[this.currentTurn].isSkill) {
			attackChar.animationState.setAnimation(0, 'Skill', false);
			attackSkill.resetSkill();
		} else {
			attackChar.animationState.setAnimation(0, 'NormalAttack', false);
		}
	}

	create() {
		this.gamebg = this.add.image(0, 0, 'main', 'bg');
		this.gamebg.x = this.gamebg.width / 2;
		this.gamebg.y = this.gamebg.height / 2;

		this.topbar = this.add.image(0, 0, 'main', 'bottom_menu_bg');
		this.topbar.x = this.topbar.width / 2;
		this.topbar.y = this.gamebg.height - this.topbar.height;

		this.inventorybg = this.add.image(0, 0, 'main', 'player_equipments_bg');
		this.inventorybg.x = this.inventorybg.width / 2;
		this.inventorybg.y = this.topbar.y - this.topbar.height / 2 - this.inventorybg.height / 2;

		this.mutantlight = this.add.image(0, 0, 'main', 'mutant_light');
		this.mutantlight.x = this.gamebg.x;
		this.mutantlight.y = this.topbar.y - 140;

		this.shibaicon = this.add.image(0, 0, 'main', 'shiba_icon');
		this.shibaicon.x = this.gamebg.x;
		this.shibaicon.y = this.topbar.y - 170;

		this.mutantmachine = this.add.image(0, 0, 'main', 'mutant_machine');
		this.mutantmachine.x = this.gamebg.x;
		this.mutantmachine.y = this.topbar.y - 100;

		this.button0 = new SimpleImageButton(this, this.gamebg.x, this.topbar.y, 'mutant_text');

		this.button1 = new ImageButton(this, 70, this.topbar.y - 30, 'menu_dungeon', 'Battle');
		this.button2 = new ImageButton(this, 190, this.topbar.y - 30, 'menu_upgrade', 'Upgrade');
		this.button2.disableBtn = true;

		this.button3 = new ImageButton(this, this.gamebg.width - 70, this.topbar.y - 30, 'menu_gift', 'Reward');
		this.button3.disableBtn = true;
		this.button4 = new ImageButton(this, this.gamebg.width - 190, this.topbar.y - 30, 'menu_arena', 'Arena');
		this.button4.disableBtn = true;

		this.headEquip = new EquipSlot(this, 170, 800, 'head');
		this.bodyEquip = new EquipSlot(this, this.headEquip.x, this.headEquip.y + 100, 'body');
		this.footEquip = new EquipSlot(this, this.headEquip.x, this.bodyEquip.y + 100, 'leg');
		this.leftHandEquip = new EquipSlot(this, this.headEquip.x + 100, this.bodyEquip.y, 'hand_left');
		this.rightHandEquip = new EquipSlot(this, this.headEquip.x - 100, this.bodyEquip.y, 'hand_right');

		this.button0.on('pointerup', event => {
			this.loadNewItem();
		});
		this.battleInfo = [];
		this.currentTurn = -1;

		this.triggerTimer = this.time.addEvent({
			callback: this.timerEvent,
			callbackScope: this,
			delay: 4000, // 1000 = 1 second
			loop: true,
		});

		this.loadNewOpponent();

		this.anims.create({
			key: 'flame',
			frames: this.anims.generateFrameNumbers('pipo'),
			frameRate: 16,
			repeat: -1,
		});

		this.spriteLoad = this.add.sprite(360, 1000);
		//sprite.play('flame');
	}

	resetBattleField() {
		if (this.leftPlayer) {
			this.leftPlayer.destroy();
		}

		if (this.rightPlayer) {
			this.rightPlayer.destroy();
		}

		if (this.myName) {
			this.myName.destroy();
		}

		if (this.oppName) {
			this.oppName.destroy();
		}

		if (this.leftSkill) {
			this.leftSkill.destroy();
		}

		if (this.rightSkill) {
			this.rightSkill.destroy();
		}

		if (this.leftHP) {
			this.leftHP.destroy();
		}

		if (this.rightHP) {
			this.rightHP.destroy();
		}
		this.battleInfo = null;
		this.currentTurn = -1;
	}

	async loadNewItem() {
		this.spriteLoad.play('flame');
		this.spriteLoad.setVisible(true);
		const itemResult = await summonItem();
		this.spriteLoad.stop();
		this.spriteLoad.setVisible(false);
		const oldItem = this.myEquipments[itemResult.data.position];

		var updateObj = { oldItem: oldItem, newItem: itemResult.data };

		var newPopupScene = this.scene.launch('NewEquipPopup', updateObj);
		console.log(newPopupScene);
	}

	updateEquipments() {
		if (this.myEquipments == null) return;
		if (this.myEquipments.head) {
			this.headEquip.setItem(this.myEquipments.head);
		}
		if (this.myEquipments.body) {
			this.bodyEquip.setItem(this.myEquipments.body);
		}
		if (this.myEquipments.leg) {
			this.footEquip.setItem(this.myEquipments.leg);
		}
		if (this.myEquipments.hand_left) {
			this.leftHandEquip.setItem(this.myEquipments.hand_left);
		}
		if (this.myEquipments.hand_right) {
			this.rightHandEquip.setItem(this.myEquipments.hand_right);
		}
	}

	async loadNewOpponent() {
		const battleResult = await startBattle();
		console.log(battleResult);

		this.myEquipments = battleResult.data.myData.equipments;

		this.updateEquipments();

		if (battleResult.data.opponent) {
			this.currentTurn = -1;
			this.battleInfo = battleResult.data.battleInfo;

			this.leftPlayer = this.add.spine(150, 500, battleResult.data.myData.skin + '-data', battleResult.data.myData.skin + '-atlas');
			this.leftPlayer.setOrigin(0.5, 0.5);
			this.leftPlayer.scaleX = 0.5;
			this.leftPlayer.scaleY = 0.5;
			this.leftPlayer.animationState.setAnimation(0, 'Idle', true);

			this.rightPlayer = this.add.spine(550, 500, battleResult.data.opponentData.skin + '-data', battleResult.data.opponentData.skin + '-atlas');
			this.rightPlayer.setOrigin(0.5, 0.5);
			this.rightPlayer.scaleX = -0.5;
			this.rightPlayer.scaleY = 0.5;
			this.rightPlayer.animationState.setAnimation(0, 'Idle', true);

			this.leftSkill = new SkillButton(this, 150, 600, battleResult.data.myData.skill.name, battleResult.data.myData.skill.dmg_gauge);
			this.rightSkill = new SkillButton(this, 550, 600, battleResult.data.opponentData.skill.name, battleResult.data.opponentData.skill.dmg_gauge);

			this.leftHP = new ProgressBar(this, 50, 140, 'hp_green', battleResult.data.myData.hp);
			this.rightHP = new ProgressBar(this, 470, 140, 'hp_red', battleResult.data.opponentData.hp);

			this.myName = this.add.text(this.leftHP.x, this.leftHP.y - 40, Telegram.WebApp.initDataUnsafe.user.username, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'left' }).setOrigin(0, 0);
			this.oppName = this.add.text(this.rightHP.x + this.rightHP.width, this.rightHP.y - 40, battleResult.data.opponent, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'right' }).setOrigin(1, 0);
		}
	}

	createReward(maxParticle = 5) {
		const emitter = this.add.particles(550, 500, 'main', {
			frame: 'reward_exp_icon',
			bounce: 0.5,
			speed: { min: 600, max: 700 },
			maxVelocityX: 1200,
			maxVelocityY: 1200,
			angle: { min: -100, max: -80 },
			gravityY: 1600,
			//y: { start: 30, end: 500, ease: 'bounce.out' },
			lifespan: 4000,

			maxParticles: maxParticle,
			sortProperty: 'lifeT',
			sortOrderAsc: true,
		});

		emitter.addParticleBounds(0, 0, 700, 650, false, false, false, true);

		this.time.addEvent({
			callback: function() {
				emitter.gravityY = 0;
				const well = emitter.createGravityWell({
					x: -550,
					y: -550,
					power: 10,
					epsilon: 10,
					gravity: 1600,
				});
			},
			callbackScope: this,
			delay: 2000, // 1000 = 1 second
			loop: false,
		});
	}

	getSkillName(skinName) {
		if (skinName == 'dogzilla') {
			return 'skill_2';
		} else {
			return 'skill_1';
		}
	}
}
