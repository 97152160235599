import { Scene } from 'phaser';

export default class Preloader extends Scene {
	constructor() {
		super('Preloader');
	}

	async init() {
		const loadingText = this.add.text(360, 450, '', { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0);

		//  We loaded this image in our Boot Scene, so we can display it here
		this.add.image(360, 220, 'game-logo-512');

		//  A simple progress bar. This is the outline of the bar.
		this.add.rectangle(360, 684, 468, 14).setStrokeStyle(1, 0xffffff);

		//  This is the progress bar itself. It will increase in size from the left based on the % of progress.
		const bar = this.add.rectangle(360 - 230, 684, 4, 10, 0xffffff);

		//  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
		this.load.on('progress', progress => {
			//  Update the progress bar (our bar is 464px wide, so 100% = 464px)
			bar.width = 4 + 460 * progress;
		});
		this.connected = false;
		loadingText.text = 'Connecting to server...';
		console.log('Load data...');
		const user = await getData();
		loadingText.text = user.uid + ' connected.';
		console.log('Connected.');
		this.connected = true;
	}

	preload() {
		this.load.atlas('main', './dogmutant.png', './dogmutant.json');

		this.load.spineJson('dogzilla-data', './dogmutant_spine/dogzilla.json');
		this.load.spineAtlas('dogzilla-atlas', './dogmutant_spine/dogzilla.atlas.txt');

		this.load.spineJson('dog_kong-data', './dogmutant_spine/dog_kong.json');
		this.load.spineAtlas('dog_kong-atlas', './dogmutant_spine/dog_kong.atlas.txt');

		this.load.spineJson('dogbase-data', './dog_base.json');
		this.load.spineAtlas('dogbase-atlas', './dog_base.atlas.txt');

		this.load.spineJson('dognom-data', './dogmutant_spine/dognom.json');
		this.load.spineAtlas('dognom-atlas', './dogmutant_spine/dognom.atlas.txt');

		this.load.spineJson('dog_shiba-data', './dogmutant_spine/dog_shiba.json');
		this.load.spineAtlas('dog_shiba-atlas', './dogmutant_spine/dog_shiba.atlas.txt');

		this.load.spineJson('skar_king-data', './dogmutant_spine/skar_king.json');
		this.load.spineAtlas('skar_king-atlas', './dogmutant_spine/skar_king.atlas.txt');

		this.load.spineJson('dogkong-impact-data', './dogmutant_spine/impact_skill.json');
		this.load.spineAtlas('dogkong-impact-atlas', './dogmutant_spine/impact_skill.atlas.txt');

		this.load.spineJson('irondog-data', './dogmutant_spine/irondog.json');
		this.load.spineAtlas('irondog-atlas', './dogmutant_spine/irondog.atlas.txt');

		this.load.spritesheet('pipo', './pipo-mapeffect013b.png', { frameWidth: 192, frameHeight: 192 });
	}

	create() {
		//  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
		//  For example, you can define global animations here, so we can use them in other scenes.
		//  Move to the MainMenu. You could also swap this for a Scene Transition, such as a camera fade.
		//
	}

	update() {
		if (this.connected) {
			this.scene.start('MainScene');
		}
	}
}
