import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';

export default class NewEquipPopup extends Phaser.Scene {
	constructor() {
		super('NewEquipPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'NewEquipPopup' });
	}

	async init(data) {
		console.log(data);

		this.compareData = data;
	}

	preload() {}

	create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: 'white', align: 'left' };
		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main', 'popup_bg', 550, 700, 40, 40, 40, 50);

		var color = ['gray', 'green', 'blue', 'purple', 'red', 'yellow'];
		var rarity = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary', 'Unique'];

		var updown = [0, 0, 0];

		if (this.compareData.oldItem != null) {
			if (this.compareData.oldItem.atk < this.compareData.newItem.atk) {
				updown[1] = 1;
			}
			if (this.compareData.oldItem.atk > this.compareData.newItem.atk) {
				updown[1] = -1;
			}

			if (this.compareData.oldItem.def < this.compareData.newItem.def) {
				updown[2] = 1;
			}
			if (this.compareData.oldItem.def > this.compareData.newItem.def) {
				updown[2] = -1;
			}

			var row1 = new ItemStatRow(this, 360, 450, '[' + rarity[this.compareData.oldItem.rarity] + '] ' + this.compareData.oldItem.name, this.compareData.newItem.position + '_' + this.compareData.oldItem.image, color[this.compareData.oldItem.rarity], { lv: this.compareData.oldItem.level, atk: this.compareData.oldItem.atk, def: this.compareData.oldItem.def });
		}

		var row2 = new ItemStatRow(this, 360, 730, '[' + rarity[this.compareData.newItem.rarity] + '] ' + this.compareData.newItem.name, this.compareData.newItem.position + '_' + this.compareData.newItem.image, color[this.compareData.newItem.rarity], { lv: this.compareData.newItem.level, atk: this.compareData.newItem.atk, def: this.compareData.newItem.def }, updown);

		this.sellButton = new PopupButton(this, popupBG.x - popupBG.width / 2 + 150, popupBG.y + popupBG.height / 2 - 70, 'button_red', 'Sell');
		this.equipButton = new PopupButton(this, popupBG.x - popupBG.width / 2 + 400, popupBG.y + popupBG.height / 2 - 70, 'button_green', 'Equip');

		r1.on('pointerup', function() {
			this.scene.scene.stop('NewEquipPopup');
		});

		this.sellButton.on('pointerup', function() {
			this.scene.scene.stop('NewEquipPopup');
		});

		this.equipButton.on('pointerup', async function() {
			this.setText('Loading...');
			const returnData = await equipPendingItem();
			this.scene.scene.get('MainScene').myEquipments = returnData.data.equipments;
			this.scene.scene.get('MainScene').updateEquipments();
			this.scene.scene.stop('NewEquipPopup');
		});
	}
}
