export default class ItemStatRow extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, icon, rarely, valueobj, updownArray) {
		console.log('updownarr' + updownArray);

		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: 'white', align: 'left' };

		super(scene, x, y);
		scene.add.existing(this);

		var row_bg = scene.add.nineslice(0, 0, 'main', 'popup_row_bg', 500, 250, 31, 5, 7, 32);
		var equipbg = scene.add.image(-190, -60, 'main', 'equip_rarity_' + rarely);
		var equipitem = scene.add.image(equipbg.x, equipbg.y, 'main', icon);

		var itemname = scene.add.text(equipbg.x + equipbg.width, equipbg.y - equipbg.height / 2, name, LABEL_TEXT_CONFIG);

		this.setSize(row_bg.width, row_bg.height);
		this.setInteractive();

		this.add(row_bg);
		this.add(equipbg);
		this.add(equipitem);
		this.add(itemname);

		var startX = itemname.x;
		var startY = itemname.y + 50;
		var lineGap = 0;
		var index = 0;
		Object.keys(valueobj).forEach(key => {
			console.log(`${key}: ${valueobj[key]}`);
			var labelName = scene.add.text(startX, startY + lineGap, key.toUpperCase(), LABEL_TEXT_CONFIG);
			var labelValue = scene.add.text(startX + 280, startY + lineGap, valueobj[key], LABEL_TEXT_CONFIG);
			this.add(labelName);
			this.add(labelValue);
			if (updownArray != null) {
				if (updownArray[index] == 1) {
					var updownIcon = scene.add.image(labelValue.x + labelValue.width, startY + lineGap, 'main', 'power_up').setOrigin(0, 0);
					this.add(updownIcon);
				}

				if (updownArray[index] == -1) {
					var updownIcon = scene.add.image(labelValue.x + labelValue.width, startY + lineGap, 'main', 'power_down').setOrigin(0, 0);
					this.add(updownIcon);
				}
			}

			lineGap += 40;
			index += 1;
		});
	}
}
