import Phaser from 'phaser';
import ImageButton from '../components/Button';
import SkillButton from '../components/SkillButton';
import FloatingNumbersPlugin from '../components/FloatingNumbersPlugin';
export default class RoosterBattleScene extends Phaser.Scene {
	constructor() {
		super('RoosterBattleScene');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'RoosterBattleScene' });
	}

	preload() {
		this.load.atlas('main', './dogmutant.png', './dogmutant.json');

		this.load.spineBinary('nguyetcungke-data', './rooster_spine/NguyetCungKe.skel');
		this.load.spineAtlas('nguyetcungke-atlas', './rooster_spine/NguyetCungKe.atlas');

		this.load.spineBinary('anthienke-data', './rooster_spine/AnThienKe.skel');
		this.load.spineAtlas('anthienke-atlas', './rooster_spine/AnThienKe.atlas');

		this.load.scenePlugin('floatingNumbersPlugin', FloatingNumbersPlugin, 'floatingNumbersPlugin', 'floatingNumbers');

		this.load.rexWebFont({
			google: {
				families: ['Barlow Condensed'],
			},
			// testString: undefined,
			// testInterval: 20,
		});
	}

	createNumber(targetObj) {
		var dognumber = this.floatingNumbers.createFloatingText({
			textOptions: {
				fontFamily: 'Barlow Condensed',
				fontSize: 42,
				color: '#fff',
				strokeThickness: 2,
				fontWeight: 'bold',
				stroke: '#000000',
				shadow: {
					offsetX: 0,
					offsetY: 0,
					color: '#000',
					blur: 4,
					stroke: true,
					fill: false,
				},
			},
			text: Phaser.Math.Between(100, 900),
			align: 'top-center',
			parentObject: targetObj,
			animation: 'up',
			timeToLive: 500,
			animationEase: 'Sine.easeOut',
		});
	}

	timerEvent() {
		var value = Phaser.Math.Between(0, 1);
		var attackChar = null;
		var hitChar = null;
		var attackSkill = null;
		if (value == 0) {
			attackChar = this.leftRooster;
			attackSkill = this.kongSkill;
			hitChar = this.rightRooster;
		} else {
			attackChar = this.rightRooster;
			attackSkill = this.dogzillaSkill;
			hitChar = this.leftRooster;
		}

		attackChar.animationState.addListener({
			complete: entry => {
				attackChar.animationState.clearListeners();
				attackChar.animationState.setAnimation(0, 'idle', true);
			},
			event: (entry, event) => {
				if (event.data.name == 'hit') {
					this.createNumber(hitChar);
					var hitId = Phaser.Math.Between(1, 2);
					hitChar.animationState.setAnimation(0, 'hit ' + hitId, false);
					hitChar.animationState.addAnimation(0, 'idle', true);

					if (attackSkill.getCanFire() == true) {
						attackSkill.resetFireSkill();
					} else {
					}
				}
			},
		});

		if (attackSkill.getCanFire() == true) {
			attackChar.animationState.setAnimation(0, 'attack', false);
		} else {
			attackChar.animationState.setAnimation(0, 'attack', false);
		}
	}

	create() {
		this.gamebg = this.add.image(0, 0, 'main', 'bg');
		this.gamebg.x = this.gamebg.width / 2;
		this.gamebg.y = this.gamebg.height / 2;

		this.topbar = this.add.image(0, 0, 'main', 'bottom_menu_bg');
		this.topbar.x = this.topbar.width / 2;
		this.topbar.y = this.gamebg.height - this.topbar.height;

		this.inventorybg = this.add.image(0, 0, 'main', 'player_equipments_bg');
		this.inventorybg.x = this.inventorybg.width / 2;
		this.inventorybg.y = this.topbar.y - this.topbar.height / 2 - this.inventorybg.height / 2;

		this.button1 = new ImageButton(this, 70, this.topbar.y - 30, 'menu_dungeon', 'Dungeon');
		this.button2 = new ImageButton(this, 190, this.topbar.y - 30, 'menu_upgrade', 'Upgrade');

		this.button3 = new ImageButton(this, this.gamebg.width - 70, this.topbar.y - 30, 'menu_gift', 'Gift');
		this.button4 = new ImageButton(this, this.gamebg.width - 190, this.topbar.y - 30, 'menu_arena', 'Arena');

		this.leftRooster = this.add.spine(220, 500, 'nguyetcungke-data', 'nguyetcungke-atlas');
		this.leftRooster.skeleton.setSkin(this.leftRooster.skeleton.data.findSkin('silver'));
		this.leftRooster.animationState.setAnimation(0, 'idle', true);

		this.rightRooster = this.add.spine(500, 500, 'anthienke-data', 'anthienke-atlas');
		this.rightRooster.skeleton.setSkin(this.rightRooster.skeleton.data.findSkin('gold'));
		this.rightRooster.animationState.setAnimation(0, 'idle', true);
		this.rightRooster.scaleX = -1;

		this.kongSkill = new SkillButton(this, 550, 600, 'skill_1');
		this.dogzillaSkill = new SkillButton(this, 150, 600, 'skill_2');

		this.triggerTimer = this.time.addEvent({
			callback: this.timerEvent,
			callbackScope: this,
			delay: 4000, // 1000 = 1 second
			loop: true,
		});

		this.button2.on('pointerup', function() {
			this.scene.scene.launch('NewEquipPopup');
		});
	}
}
